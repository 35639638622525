import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Divider,
    EmptyPage,
    FlexColumns,
    InfoBlockHeader,
    InfoBlockItem,
    Loader,
    Padding,
    ToastPlate,
    Typography,
} from '@alphakits/ui/dist';
import { BaseForm } from '@alphakits/ui/dist/form/templates/base-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks/use-api-method';
import { OrderForSignRO } from '@escapenavigator/types/dist/order/waivers/order-to-sign.ro';
import { SignOrderDTO } from '@escapenavigator/types/dist/order/waivers/sign-order.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { useApi } from 'src/providers/api/context';

import { FormStep } from './form-step';
import { Participants } from './participants';
import { SignStep } from './sign-step';
import { SignWaiver } from './sign-waiver';

import styles from './index.module.css';

type Props = {
    orderToken: string;
    owner: boolean;
};

export const waiverUrl =
    'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/widget/waiver.webp';

export const OrderWaiver: React.FC<Props> = ({ orderToken, owner }) => {
    const { t, i18n } = useTranslation();

    const { orders } = useApi();
    const [success, setSuccess] = useState(false);
    const [isOwner, setIsOwner] = useState(owner);
    const [order, setOrder] = useState<OrderForSignRO>();

    const { getOrderToSignError, getOrderToSignLoading, getOrderToSignFetch } = useApiMethod({
        api: orders.getOrderToSign,
        successCallback: ({ data }) => {
            setOrder(data);

            if (data.participants.some((p) => p.client.email === data.client.email) && isOwner) {
                setIsOwner(false);
            }

            i18n.changeLanguage(data.language);
        },
    });

    const { signOrderWaiverError, signOrderWaiverFetch, signOrderWaiverLoading } = useApiMethod({
        api: orders.signOrderWaiver,
        successCallback: () => {
            getOrderToSignFetch({ token: orderToken });
            setSuccess(true);
        },
    });

    useEffect(() => {
        getOrderToSignFetch({ token: orderToken });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderToken]);

    const loading = getOrderToSignLoading;
    const error = getOrderToSignError || signOrderWaiverError;

    if (loading || !order) {
        return <Loader />;
    }

    if (error) {
        return (
            <ToastPlate view="negative" title="Error">
                { error?.message }
            </ToastPlate>
        );
    }

    if (!order.needWaiver) {
        return (
            <EmptyPage
                title="No need to sign waiver for this game"
                image="https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/widget/widgetComplereOrder.webp"
            />
        );
    }

    return (
        <Padding padding="32px" className={ styles.inner_wrapper }>
            { success ? (
                <FlexColumns columns={ 1 } gr={ 16 }>
                    <EmptyPage
                        title={ t('Waiver подписан') }
                        subtitle={ `${t('До встречи на игре')}!` }
                        image="https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/widget/widgetComplereOrder.webp"
                    />

                    <FlexColumns columns={ 1 } gr={ 24 }>
                        <Participants participants={ order.participants } players={ order.players } />
                        { order.participants.length < order.players && (
                            <FlexColumns columns={ 1 } gr={ 4 }>
                                <Button
                                    block={ true }
                                    onClick={ () => {
                                        setSuccess(false);
                                        setIsOwner(false);
                                    } }
                                    view="primary"
                                    size="s"
                                >
                                    { t('Подписать еще один') }
                                </Button>

                                <SignWaiver
                                    signed={ true }
                                    orderToken={ orderToken }
                                    questroomTitle={ order.questroom.title }
                                />
                            </FlexColumns>
                        ) }

                        <Divider />

                        <FlexColumns columns={ 1 } gr={ 0 }>
                            <InfoBlockHeader title={ t('Детали игры') } />
                            <InfoBlockItem label={ t('Квест') } value={ order.questroom.title } />
                            <InfoBlockItem
                                label={ t('Телефон') }
                                value={ order.questroom.location.phone }
                            />
                            <InfoBlockItem
                                label={ t('Адрес') }
                                value={ order.questroom?.location?.address }
                            />
                        </FlexColumns>
                        <Divider />

                        { order.questroom.location.prepareText && (
                            <React.Fragment>
                                <FlexColumns columns={ 1 } gr={ 0 }>
                                    <InfoBlockHeader title={ t('Как подготовиться') } />
                                    <Typography.Text view="caps">
                                        { order.questroom.location.prepareText }
                                    </Typography.Text>
                                </FlexColumns>
                                <Divider />
                            </React.Fragment>
                        ) }

                        { order.questroom.location.howToFind && (
                            <React.Fragment>
                                <FlexColumns columns={ 1 } gr={ 0 }>
                                    <InfoBlockHeader title={ t('Как найти квест') } />
                                    <Typography.Text view="caps">
                                        { order.questroom.location.howToFind }
                                    </Typography.Text>
                                </FlexColumns>
                                <Divider />
                            </React.Fragment>
                        ) }

                        { order.questroom.importantInfo && (
                            <ToastPlate view="default">{ order.questroom.importantInfo }</ToastPlate>
                        ) }
                    </FlexColumns>
                </FlexColumns>
            ) : (
                <BaseForm
                    enableReinitialize={ true }
                    initialValues={ serializeRecord(SignOrderDTO, {
                        step: 'form',
                        orderToken,
                        existingParticipantId: 0,
                        emails: order.participants.map((p) => p.client.email),
                        childs: [],
                        existingChilds: [],
                        isParticipant: true,
                        ...(isOwner ? order.client : {}),
                    }) }
                    validate={ validateByDto(t) }
                    loading={ signOrderWaiverLoading }
                    save={ signOrderWaiverFetch }
                    t={ t }
                >
                    { (props) => (
                        <FlexColumns columns={ 1 } gr={ 32 }>
                            { props.values.step === 'form' ? (
                                <FormStep
                                    order={ order }
                                    next={ () => props.setFieldValue('step', 'sign') }
                                    { ...props }
                                />
                            ) : (
                                <SignStep
                                    profileId={ order.profileId }
                                    onBack={ () => props.setFieldValue('step', 'form') }
                                    { ...props }
                                />
                            ) }
                        </FlexColumns>
                    ) }
                </BaseForm>
            ) }
        </Padding>
    );
};
