/* eslint-disable complexity */
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Amount,
    Box,
    FlexColumns,
    InfoBlockItem,
    InfoBlockWrapper,
    Loader,
    Padding,
    showError,
    SidepanelHeader,
    Skeleton,
    Status,
    SuperListHeader,
    SuperListItem,
    ToastPlate,
    Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks/use-api-method';
import { OrderPenaltyTypeEnum } from '@escapenavigator/types/dist/order-penalty/enum/order-penalty-type.enum';
import { TransactionTypeEnum } from '@escapenavigator/types/dist/transaction/enum/transaction-type.enum';
import { convertUTCDateToZonedDate } from '@escapenavigator/utils/dist';
import { useApi } from 'src/providers/api/context';

import { DoubleModal, ModalRightSide, SidePanelHeaderWrapper } from '../double-modal';
import { Participants } from '../order-waiver/participants';
import { SignWaiver } from '../order-waiver/sign-waiver';
import { PaymentMethods } from '../payment/payment-methods';
import { TransactionsList } from '../transactions-list';

import { OrderCancelation } from './cancelation';
import { getOrderData } from './get-order-data';
import { Upselling } from './upsell';
import { useOrderPayment } from './use-order-payment';

import styles from './index.module.css';

type Props = {
    orderToken: string;
    failedPayment: boolean;
};

export const Order: React.FC<Props> = ({ orderToken, failedPayment }) => {
    const { t, i18n } = useTranslation();

    const { openapiWidget, orders } = useApi();

    const { getOrderPaymentMethodsFetch, getOrderPaymentMethodsData } = useApiMethod({
        api: openapiWidget.getOrderPaymentMethods,
    });

    const { getOrderAvailableUpsellingsData, getOrderAvailableUpsellingsFetch } = useApiMethod({
        api: openapiWidget.getOrderAvailableUpsellings,
    });

    const {
        findOrderForOrderServiceData: order,
        findOrderForOrderServiceError: findOrderError,
        findOrderForOrderServiceLoading: findOrderLoading,
        findOrderForOrderServiceFetch: findOrderFetch,
    } = useApiMethod({
        api: openapiWidget.findOrderForOrderService,
        successCallback: ({ data }) => {
            i18n.changeLanguage(data.language);

            if (data.toPay > 0) {
                getOrderPaymentMethodsFetch({ token: orderToken });
            }
            if (data.slot) {
                getOrderAvailableUpsellingsFetch({ token: orderToken });
            }
        },
    });

    const { removeParticipantFetch, removeParticipantLoading } = useApiMethod({
        api: orders.removeParticipant,
        successCallback: () => findOrderFetch({ token: orderToken }),
        errorCallback: () => showError('Please, try again later'),
    });

    const { cancelOrderError, cancelOrderLoading, cancelOrderFetch } = useApiMethod({
        api: openapiWidget.cancelOrder,
        successCallback: () => findOrderFetch({ token: orderToken }),
    });

    useEffect(() => {
        findOrderFetch({ token: orderToken });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderToken]);

    const {
        capturePaypal,
        createPaypalPayment,
        createStripePayment,
        paymentData,
        setPaymentData,
        error: paymentError,
        setError,
        loading: paymentLoading,
    } = useOrderPayment({ token: orderToken, failedPayment });

    const loading = findOrderLoading || cancelOrderLoading;
    const error = findOrderError || cancelOrderError;

    const availableUpsellings = useMemo(
        () =>
            getOrderAvailableUpsellingsData?.filter(
                (u) => !order?.upsellings.some((up) => up.title === u.title),
            ),
        [getOrderAvailableUpsellingsData, order],
    );

    if (loading || !order) {
        return <Loader />;
    }

    if (error) {
        return (
            <ToastPlate view="negative" title="Error">
                { error?.message }
            </ToastPlate>
        );
    }

    const toPay = order.total - order.payed;

    const orderData = getOrderData(order, t);

    const metadataQuery = `order=${orderToken}&payee=${paymentData?.payee}&paymentStatusPage`;

    const increase = order.transactions.reduce((acc, transaction) => {
        if (
            transaction.type === TransactionTypeEnum.PROCESSING ||
            transaction.type === TransactionTypeEnum.SUCCEEDED
        ) {
            return acc + transaction.includedIncrease;
        }

        return acc;
    }, 0);

    return (
        <DoubleModal>
            <div>
                <Padding padding="0 32px 40px" className={ styles.inner_wrapper }>
                    <FlexColumns columns={ 1 } gr={ 24 }>
                        <FlexColumns columns={ 1 } gr={ 8 }>
                            <SidePanelHeaderWrapper>
                                <SidepanelHeader
                                    title={ `${t('Бронь')} №${order.code} ` }
                                    bottomAddons={ (
                                        <Status view="contrast" color={ orderData.status.color }>
                                            { ' ' }
                                            { orderData.status.title }
                                        </Status>
                                    ) }
                                />
                            </SidePanelHeaderWrapper>

                            <SuperListItem
                                disabled={ true }
                                text={ t('Дата игры') }
                                rightAddons={ (
                                    <Typography.Text view="title" weight="medium" color="primary">
                                        { order.utcDate
                                            ? convertUTCDateToZonedDate({
                                                date: order.utcDate,
                                                timeZone: order.questroom.location.timeZone,
                                                showTZ: true,
                                            })
                                            : '-' }
                                    </Typography.Text>
                                ) }
                            />

                            <SuperListItem
                                text={ t('Язык игры') }
                                disabled={ true }
                                rightAddons={ (
                                    <Typography.Text view="title" weight="medium" color="primary">
                                        { order.language.toLocaleUpperCase() }
                                    </Typography.Text>
                                ) }
                            />
                        </FlexColumns>

                        <FlexColumns columns={ 1 } gr={ 8 }>
                            <SuperListHeader
                                text={ t('Стоимость игры') }
                                rightAddons={ (
                                    <Amount
                                        value={ +order.total }
                                        currency={ order.currency }
                                        color="primary"
                                        type="decimal"
                                        weight="bold"
                                        view="primary-medium"
                                    />
                                ) }
                            />

                            <SuperListItem
                                className={ !order.slot?.rule && styles.through }
                                disabled={ true }
                                text={ `${t('Проведение игры')}, ${order.players} ${t('чел')}.` }
                                rightAddons={ (
                                    <Amount
                                        value={ +order.price[order.players] }
                                        currency={ order.currency }
                                        color="primary"
                                        type="decimal"
                                        weight="bold"
                                        view="title"
                                    />
                                ) }
                            />

                            { order.upsellings.map((upselling) => (
                                <SuperListItem
                                    key={ upselling.id }
                                    text={ `${t('Доп услуга')} "${upselling.title}"` }
                                    className={ !order.slot?.rule && styles.through }
                                    disabled={ true }
                                    rightAddons={ (
                                        <Amount
                                            value={ upselling.amount }
                                            currency={ order.currency }
                                            color="primary"
                                            type="decimal"
                                            weight="bold"
                                            view="title"
                                        />
                                    ) }
                                />
                            )) }

                            { order.penalties.map((penalty) => (
                                <SuperListItem
                                    key={ penalty.id }
                                    disabled={ true }
                                    text={
                                        penalty.type === OrderPenaltyTypeEnum.CANCELATION
                                            ? t('Штраф за отмену игры')
                                            : t('Выставлен штраф')
                                    }
                                    color="negative"
                                    rightAddons={ (
                                        <Amount
                                            value={ penalty.amount }
                                            currency={ order.currency }
                                            color="primary"
                                            type="decimal"
                                            weight="bold"
                                            view="title"
                                        />
                                    ) }
                                />
                            )) }

                            { !!increase && (
                                <SuperListItem
                                    text={ t('Наценка за онлайн оплату') }
                                    disabled={ true }
                                    rightAddons={ (
                                        <Amount
                                            value={ increase }
                                            currency={ order.currency }
                                            color="primary"
                                            type="decimal"
                                            weight="bold"
                                            view="title"
                                        />
                                    ) }
                                />
                            ) }
                        </FlexColumns>

                        { order.payed !== 0 && (
                            <FlexColumns columns={ 1 } gr={ 8 }>
                                <SuperListHeader
                                    text={ t('Платежи') }
                                    rightAddons={ (
                                        <Amount
                                            value={ +order.payed }
                                            currency={ order.currency }
                                            color="primary"
                                            type="decimal"
                                            weight="bold"
                                            view="title"
                                        />
                                    ) }
                                />

                                { order.certificates?.map((certificate) => (
                                    <SuperListItem
                                        key={ certificate.id }
                                        disabled={ true }
                                        text={ `${t('Применен сертификат')} ${certificate.code}` }
                                        rightAddons={ (
                                            <Amount
                                                value={ +certificate.nominal }
                                                currency={ order.currency }
                                                color="primary"
                                                type="decimal"
                                                weight="bold"
                                                view="title"
                                            />
                                        ) }
                                    />
                                )) }

                                { order.promocodes?.map((p) => (
                                    <SuperListItem
                                        key={ p.id }
                                        disabled={ true }
                                        text={ `${t('Применен промокод')} ${p.code}` }
                                        rightAddons={ (
                                            <Amount
                                                value={ +p.amount }
                                                currency={ order.currency }
                                                color="primary"
                                                type="decimal"
                                                weight="bold"
                                                view="title"
                                            />
                                        ) }
                                    />
                                )) }

                                { order.discounts.map((discount) => (
                                    <SuperListItem
                                        key={ discount.id }
                                        text={ t('Ручная скидка') }
                                        disabled={ true }
                                        rightAddons={ (
                                            <Amount
                                                value={ discount.amount }
                                                currency={ order.currency }
                                                color="primary"
                                                type="decimal"
                                                weight="bold"
                                                view="title"
                                            />
                                        ) }
                                    />
                                )) }

                                <TransactionsList
                                    currency={ order.currency }
                                    transactions={ order.transactions }
                                />
                            </FlexColumns>
                        ) }

                        <FlexColumns columns={ 1 } gr={ 12 }>
                            <SuperListHeader
                                text={ t('Итого к оплате') }
                                rightAddons={ (
                                    <Amount
                                        value={ toPay }
                                        currency={ order.currency }
                                        color="primary"
                                        type="decimal"
                                        weight="bold"
                                        view="title"
                                    />
                                ) }
                            />

                            { paymentError && (
                                <ToastPlate
                                    hasCloser={ true }
                                    onClose={ () => setError('') }
                                    view="negative"
                                    title="Error"
                                >
                                    { paymentError }
                                </ToastPlate>
                            ) }

                            { !!getOrderPaymentMethodsData?.length && (
                                <PaymentMethods
                                    allPaymentMethods={ getOrderPaymentMethodsData }
                                    currency={ order.currency }
                                    createPaypalPayment={ createPaypalPayment }
                                    createStripePayment={ createStripePayment }
                                    capturePaypal={ capturePaypal }
                                    loading={ paymentLoading }
                                    setFailed={ () => {
                                        setError('Payment failed');
                                        setPaymentData(undefined);
                                    } }
                                    metadataQuery={ metadataQuery }
                                    paymentData={ paymentData }
                                />
                            ) }
                        </FlexColumns>

                        { !!order.slot && !!availableUpsellings?.length && (
                            <FlexColumns columns={ 1 } gr={ 4 }>
                                <SuperListHeader text={ t('Подключите услугу') } />

                                <FlexColumns columns={ 1 } gr={ 16 }>
                                    { availableUpsellings.map((upselling) => (
                                        <Upselling
                                            key={ upselling.id }
                                            upselling={ upselling }
                                            order={ order }
                                            refetch={ () => findOrderFetch({ token: order.token }) }
                                        />
                                    )) }
                                </FlexColumns>
                            </FlexColumns>
                        ) }

                        { order.slot?.rule && (
                            <FlexColumns columns={ 1 } gr={ 4 }>
                                <SuperListHeader text={ t('Важная информация') } />
                                <FlexColumns columns={ 1 } gc={ 12 } gr={ 12 }>
                                    { order.questroom.importantInfo && (
                                        <ToastPlate view="default">
                                            { order.questroom.importantInfo }
                                        </ToastPlate>
                                    ) }

                                    <Typography.Text view="caps">
                                        { order.questroom.location.prepareText }
                                    </Typography.Text>

                                    <OrderCancelation
                                        order={ order }
                                        cancelOrder={ () => cancelOrderFetch({ token: orderToken }) }
                                    />
                                </FlexColumns>
                            </FlexColumns>
                        ) }
                    </FlexColumns>
                </Padding>
            </div>

            <ModalRightSide loading={ false }>
                <InfoBlockWrapper>
                    <Typography.Title tag="div" view="xxsmall" color="primary" weight="bold">
                        { t('Создатель') }
                    </Typography.Title>

                    { (order.client?.name || order.client?.surname) && (
                        <InfoBlockItem
                            label={ t('Имя') }
                            value={ `${order.client?.name || ''} ${order.client?.surname || ''}` }
                        />
                    ) }
                    <InfoBlockItem label={ t('Телефон') } value={ order.client?.phone || '' } />
                    <InfoBlockItem label={ t('Email') } value={ order.client?.email } />
                    <InfoBlockItem label={ t('Адрес') } value={ order.client.address || '-' } />
                </InfoBlockWrapper>

                <InfoBlockWrapper>
                    <Typography.Title tag="div" view="xxsmall" color="primary" weight="bold">
                        { t('Детали игры') }
                    </Typography.Title>
                    <InfoBlockItem label={ t('Квест') } value={ order.questroom.title } />
                    <InfoBlockItem label={ t('Телефон') } value={ order.questroom.location.phone } />
                    <InfoBlockItem label={ t('Адрес') } value={ order.questroom?.location?.address } />
                </InfoBlockWrapper>

                <InfoBlockWrapper>
                    <Typography.Title tag="div" view="xxsmall" color="primary" weight="bold">
                        { t('Как найти квест') }
                    </Typography.Title>
                    <Typography.Text view="caps">
                        { order.questroom.location.howToFind }
                    </Typography.Text>
                </InfoBlockWrapper>

                { order.needWaiver && (
                    <Skeleton visible={ removeParticipantLoading }>
                        <Box padding="sm" rounded="sm" background="var(--color-bg-primary)">
                            <FlexColumns columns={ 1 } gr={ 16 }>
                                <Participants
                                    onDelete={ (participantId) =>
                                        removeParticipantFetch({ participantId, token: orderToken }) }
                                    participants={ order.participants }
                                    players={ order.players }
                                />

                                { order.participants.length < order.players && (
                                    <React.Fragment>
                                        <Typography.Text view="primary-medium" color="primary">
                                            { t('Не забудьте подписать вейвер') }
                                        </Typography.Text>

                                        <FlexColumns columns={ 1 } gr={ 4 }>
                                            <SignWaiver
                                                orderToken={ orderToken }
                                                questroomTitle={ order.questroom.title }
                                            />
                                        </FlexColumns>
                                    </React.Fragment>
                                ) }
                            </FlexColumns>
                        </Box>
                    </Skeleton>
                ) }
            </ModalRightSide>
        </DoubleModal>
    );
};
