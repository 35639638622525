/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@alphakits/ui';

import styles from './index.module.css';

export const SignaturePad = ({
    onChange,
    dataUrl,
}: {
    dataUrl: string;
    onChange: (dataUrl: string | null) => void;
}) => {
    const canvasRef = useRef(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const { t } = useTranslation();

    const getMousePos = (e) => {
        const rect = canvasRef.current.getBoundingClientRect();
        const clientX = e.clientX || (e.touches ? e.touches[0].clientX : 0);
        const clientY = e.clientY || (e.touches ? e.touches[0].clientY : 0);

        return {
            x: clientX - rect.left,
            y: clientY - rect.top,
        };
    };

    const resizeCanvas = () => {
        const canvas = canvasRef.current;
        const ratio = window.devicePixelRatio || 1;

        // Увеличиваем размер Canvas в зависимости от плотности пикселей
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext('2d').scale(ratio, ratio);
    };

    useEffect(() => {
        resizeCanvas();
        window.addEventListener('resize', resizeCanvas);

        return () => window.removeEventListener('resize', resizeCanvas);
    }, []);

    const startDrawing = (e) => {
        document.body.style.overflow = 'hidden';

        e.preventDefault(); // предотвращает скролл на мобильных устройствах
        const ctx = canvasRef.current.getContext('2d');

        ctx.lineJoin = 'round'; // Сглаживание углов
        ctx.lineCap = 'round'; // Сглаживание концов линий
        ctx.beginPath();
        ctx.moveTo(getMousePos(e).x, getMousePos(e).y);
        setIsDrawing(true);
    };

    const draw = (e) => {
        e.preventDefault(); // предотвращает скролл на мобильных устройствах
        if (!isDrawing) return;
        const ctx = canvasRef.current.getContext('2d');

        ctx.lineTo(getMousePos(e).x, getMousePos(e).y);
        ctx.strokeStyle = '#000';
        ctx.lineWidth = 2;
        ctx.stroke();
    };

    const saveSignature = () => {
        const c = canvasRef.current;

        const ctx = c.getContext('2d');
        const pixels = ctx.getImageData(0, 0, c.width, c.height);
        const bounds = {
            top: null,
            left: null,
            right: null,
            bottom: null,
        };
        const len = pixels.data.length;
        let isEmpty = true;

        for (let i = 0; i < len; i += 4) {
            if (pixels.data[i + 3] !== 0) {
                // Check alpha channel
                isEmpty = false;
                const x = (i / 4) % c.width;
                // eslint-disable-next-line no-bitwise
                const y = ~~(i / 4 / c.width);

                if (bounds.top === null) bounds.top = y;
                if (bounds.left === null || x < bounds.left) bounds.left = x;
                if (bounds.right === null || x > bounds.right) bounds.right = x;
                if (bounds.bottom === null || y > bounds.bottom) bounds.bottom = y;
            }
        }

        if (isEmpty) {
            onChange(null);
        }

        const trimmedWidth = bounds.right - bounds.left;
        const trimmedHeight = bounds.bottom - bounds.top;
        const trimmed = ctx.getImageData(bounds.left, bounds.top, trimmedWidth, trimmedHeight);

        const newCanvas = document.createElement('canvas');

        newCanvas.width = trimmedWidth;
        newCanvas.height = trimmedHeight;

        const newCtx = newCanvas.getContext('2d');

        newCtx.putImageData(trimmed, 0, 0);

        return onChange(newCanvas.toDataURL('image/png'));
    };

    const stopDrawing = (e) => {
        e.preventDefault(); // предотвращает скролл на мобильных устройствах
        const ctx = canvasRef.current.getContext('2d');

        ctx.closePath();
        setIsDrawing(false);
        saveSignature();
        setTimeout(() => {
            document.body.style.overflow = '';
        }, 100);
    };

    const clearCanvas = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        onChange(null);
    };

    return (
        <Box
            className={ styles.canvas }
            background="var(--color-bg-secondary)"
            rounded="md"
            border={ true }
        >
            <canvas
                ref={ canvasRef }
                onMouseDown={ startDrawing }
                onMouseMove={ draw }
                onMouseUp={ stopDrawing }
                onMouseOut={ stopDrawing }
                onTouchStart={ startDrawing }
                onTouchMove={ draw }
                onTouchEnd={ stopDrawing }
                style={ {
                    width: '100%',
                    height: '360px',
                } }
            />

            { !isDrawing && !dataUrl && (
                <Typography.Text className={ styles.text } view="caps" color="tertiary">
                    { t('Поставьте подпись тут') }...
                </Typography.Text>
            ) }

            <Button className={ styles.clear } size="xs" view="outlined" onClick={ clearCanvas }>
                { t('Очистить') }
            </Button>
        </Box>
    );
};
